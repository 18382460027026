<template>
  <div class="marketplace-language-selector" v-if="translations && selected_language">
    <div class="marketplace-language-selector__selected">
      <span>{{ $translate("products.selected_language") }}:</span>
      <img
        :src="`/static/icons/countries/${selected_language}.svg`"
        :alt="`${selected_language} language flag`"
        @click="selecting_language = !selecting_language"
      >
    </div>
    <transition name="show-languages">
      <div v-if="selecting_language" class="marketplace-language-selector__options">
        <img
          v-for="language in available_languages.length ? available_languages : translated_languages"
          :key="`marketplace-language-selector-flag-${language}`"
          :src="`/static/icons/countries/${language}.svg`"
          :alt="`${language} language flag`"
          @click="select_language(language)"
        >
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { default_language, translated_languages } from "../../../Shared/constants/other"

export default {
  props: {
    translations: {
      type: Object | undefined,
      required: false,
      default: () => ({})
    },
    admin_selected_language: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      selected_language: "",
      available_languages: [],
      selecting_language: false,
      translated_languages
    }
  },
  computed: {
    ...mapState([
      "user"
    ]),
  },
  watch: {
    translations(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) this.update_languages()
    },
    $route: {
      handler() {
        this.update_languages()
      },
      deep: true
    }
  },
  mounted() {
    this.selected_language = this.admin_selected_language
    this.update_languages()
  },
  methods: {
    update_languages() {
      if (this.admin_selected_language) return
      if (!this.translations) {
        this.$emit("language_changed", "")
        return this.selected_language = ""
      }

      this.available_languages = this.translations ? Object.keys(this.translations || {}) : []
      let correct_language

      if (this.user && this.user.info && this.available_languages.includes(this.user.info.language)) correct_language = this.user.info.language
      else if (this.available_languages.includes(default_language)) correct_language = default_language
      else correct_language = this.available_languages[0]

      this.selected_language = correct_language
      this.$emit("language_changed", correct_language)
    },
    select_language(language) {
      this.selected_language = language
      this.selecting_language = false
      this.$emit("language_changed", language)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;

  .marketplace-language-selector {
    position: relative;

    img {
      display: block;
      width: $medium-icon !important;
      height: $medium-icon !important;
      object-fit: cover;
      border-radius: $border-radius;
      object-position: center;
      margin-bottom: 5px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__selected {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      img {
        margin-left: $spacing--tiny;
        width: $small-icon !important;
        height: $small-icon !important;
      }

      @media (max-width: $tablet--small) {
        span {
          display: none;
        }

        img {
          margin-left: 0;
          width: $medium-small-icon !important;
          height: $medium-small-icon !important;
        }
      }
    }

    &__options {
      position: absolute;
      top: calc(100% + #{$spacing--small});
      right: 0;
      padding: $spacing--tiny;
      z-index: 2;
      box-shadow: 0 8px 20px -6px $shadow-color;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      background-color: $pure-white;

      img {
        border: 1px solid $grey--light;
      }

      @media (max-width: $tablet--small) {
        right: -#{$spacing--tiny};
      }
    }

    .show-languages-enter-active, .show-languages-leave-active {
      transition: .5s;
    }
    .show-languages-enter, .show-languages-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
</style>
