<template>
  <div class="basket" :class="{ 'basket--marketplace': is_marketplace_environment }">
    <meta name="robots" content="noindex,nofollow">
    <div v-if="local_loader || basket_loader" class="basket__loading">
      <Loader />
    </div>
    <div v-if="basket_items.length > 0 || order_links.length" class="basket__wrapper" :class="{ 'basket__wrapper--no-items': !basket_items.length }">
      <h1 class="title title__normal basket__title marketplace-title">
        {{ translations.shopping_basket_title || $translate("basket.title") }}
      </h1>

      <!----- Basket Items ----->

      <div v-if="basket_items.length || (!basket_items.length && !order_links.length)" class="basket__items">
        <div v-if="show_regular_store_basket_items" class="basket__items-group">
          <Basket_item
            v-for="(item, index) in basket_items"
            :key="`basket-item-${index}`"
            :product_data="item"
            :selected_language="selected_language"
          />
        </div>
        <div 
          v-else-if="basket_items.length"
          v-for="(items, id) in get_correct_product_groups"
          :key="`basket-item-group-${id}`"
          class="basket__items-group"
          :class="{
            'basket__items-group--selected': selected_group_id === id,
            'basket__items-group--unselected': selected_group_id !== id && selected_group,
            'basket__items-group--marketplace': is_marketplace_environment
          }"
        >
          <div class="basket__seller" v-if="is_marketplace_environment">
            {{ $translate("basket.seller_title") }}
            <a v-if="items[0].project_domain" :href="`https://${items[0].project_domain}`" target="_blank">{{ items[0].project_name }}</a>
            <span v-else-if="items[0].project_id">{{ items[0].project_name || $translate("basket.unknown_seller") }}</span>
            <language_selector
              :translations="get_correct_product_prices(items)"
              @language_changed="language => update_language(language, id)"
            />
          </div>
          <p v-else-if="items[0].project_id" class="basket__items-group-title">
            {{ translations.this_is_a_cross_sold_product }}
          </p>
          <Basket_item
            v-for="(item, index) in items"
            :key="`basket-item-${index}`"
            :product_data="item"
            :disable_click="!!selected_group_id && selected_group_id !== id"
            :emit_group_clear="items.length === 1"
            :selected_language="selected_languages[id] || selected_language"
            @clear_selected_group="deselect_group"
          />
          <div class="basket__items-group-bottom">
            <div
              class="basket__items-group-remove"
              @click="(!selected_group_id || selected_group_id === id) && clear_group(id)"
            >{{ translations.remove_group_products || $translate("basket.remove_group_products")  }}</div>
            <div
              v-if="selected_group_id && selected_group_id === id"
              class="marketplace-button marketplace-button--black-and-white marketplace-button--slim"
              @click="deselect_group">{{ translations.cancel_selection_of_group || $translate("basket.cancel_selection_of_group") }}
            </div>
            <div
              v-else
              class="marketplace-button marketplace-button--basic marketplace-button--slim"
              @click="!selected_group_id && select_group(items, id)">{{ translations.pay_for_group || $translate("basket.pay_for_group") }}
            </div>
          </div>
        </div>
        <h3 v-else-if="!basket_items.length && is_marketplace_environment && order_links.length" class="basket__empty-with-orders">
          {{ translations.empty_basket || $translate("basket.empty_basket") }}
        </h3>

        <!----- Clear basket button for client store ----->

        <div
          v-if="basket_items.length && !is_marketplace_environment"
          class="basket__clear-basket"
          @click="clear_basket"
        >
          <trash_can/>
          {{ translations.clear_basket || $translate("basket.clear_basket") }}
        </div>
      </div>

      <!----- Clear basket button for marketplace ----->
      <div
        v-if="basket_items.length && is_marketplace_environment"
        class="basket__clear-basket"
        @click="clear_basket"
      >
        <trash_can/>
        {{ translations.clear_basket || $translate("basket.clear_basket") }}
      </div>

      <div
        v-if="order_links.length"
        class="basket__order-links"
      >
        <h2 class="basket__order-links-title">
          {{ translations.completed_orders || $translate("basket.completed_orders") }}:
        </h2>
        <div
          v-for="({ route, products, project_domain, payment_method }, index) in order_links"
          :key="`link-${index}`"
          class="basket__order-link"
        >
          <b>{{ index + 1 }}. </b>
          <span
            v-html="translations.view_your_order_message ?
              insert_variables_into_string(translations.view_your_order_message, [{ type: 'products', value: products }]) :
              $translate(
                'basket.view_your_order_message',
                {
                  seller: project_domain || $translate('basket.unknown_seller'),
                  products: products,
                }
              )"
          />
          <router-link :to="route" target="_blank">{{ translations.here_link || $translate("basket.here_link") }}</router-link>
          <thank_you_page v-if="payment_method !== payment_methods.paypal" :short_version="true"/>
        </div>
      </div>
      <Basket_info
        v-if="basket_items.length"
        :selected_group_currency="get_current_group_currency"
        :selected_group_language="selected_languages[selected_group_id]"
        :is_product_cross_selling="Boolean(has_basket_cross_sold_product && order_links.length && !is_marketplace_environment)"
        @select_group="select_group"
      />
    </div>
    <p
      v-if="!basket_loader && !local_loader && !basket_items.length && !order_links.length"
      class="title marketplace-title"
    >{{ translations.empty_basket || $translate("basket.empty_basket") }}</p>
  </div>
</template>

<script>
import Vue from "vue"
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import Loader from "@/applications/Shared/components/Loader/index.vue"
import Basket_item from "./utils/basket/basket_item"
import Basket_info from "./utils/basket/basket_info"
import { CLIENT_STORE } from "../../Client/constants/other"
import un_bind from "../methods/un_bind"
import language_selector from "../../Marketplace/components/utils/language_selector"
import trash_can from "./icon_components/trash-can"
import { current_store_key, payment_methods, SHARED_STORE } from "../constants/other"
import {
  CLEAR_ORDER_LINKS,
  GET_PRODUCT_PRICE, SET_WHOLE_BASKET, TOGGLE_PREVIEW_BASKET,
  UPDATE_BASKET_GROUPS, UPDATE_SELECTED_BASKET_GROUP
} from "../stores/Shared/constants"
import insert_variables_into_string from "../../Client/methods/insert_variables_into_string"
import thank_you_page from "../../Client/components/default-pages/Thank_you"
import { UPDATE_TEMP_ORDER_DATA } from "../../Client/stores/Client/constants"

export default {
  components: {
    Basket_item,
    Basket_info,
    Loader,
    language_selector,
    trash_can,
    thank_you_page
  },
  data() {
    return {
      local_loader: false,
      selected_group_id: undefined,
      selected_languages: {},
      payment_methods,
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "selected_language"
    ]),
    ...mapState(SHARED_STORE, [
      "basket_items",
      "basket_loader",
      "basket_product_groups",
      "selected_group",
      "order_links"
    ]),
    ...mapGetters(SHARED_STORE, {
      products_price: GET_PRODUCT_PRICE
    }),
    ...mapState([
      "is_marketplace_environment",
    ]),
    show_regular_store_basket_items() {
      return !this.is_marketplace_environment &&
        this.basket_items.length &&
        !this.has_basket_cross_sold_product &&
        !this.order_links.length
    },
    has_basket_cross_sold_product() {
      return this.basket_items.some(({ project_id }) => project_id)
    },
    get_correct_product_groups() {
      return this.is_marketplace_environment ?
        this.basket_product_groups :
        this.basket_items.reduce((total, product) => ({
          ...total,
          [product.project_id || current_store_key]: [
            ...(total[product.project_id || current_store_key] || []),
            product
          ]
        }), {})
    },
    get_current_group_currency() {
      if (!this.selected_group_id) return ""

      if (
        this.get_correct_product_groups[this.selected_group_id] &&
        this.selected_languages[this.selected_group_id] &&
        this.get_correct_product_groups[this.selected_group_id][0].prices
      ) return this.get_correct_product_groups[
          this.selected_group_id
        ]?.[0]?.prices[
          this.selected_languages[this.selected_group_id]
        ]?.currency_code
        
      return ""
    }
  },
  watch: {
    basket_items: {
      handler() {
        this.update_product_groups()
      },
      deep: true
    },
    selected_group(val) {
      if (!val) this.selected_group_id = undefined
    },
    selected_language(lang) {
      Object.keys(this.get_correct_product_groups).forEach(id => this.update_language(lang, id))
    }
  },
  async mounted() {
    if (this.$route.query.load === undefined) {
      this.local_loader = true
      await this.set_whole_basket(this.basket_items)
      this.update_product_groups()

      if (!this.is_marketplace_environment) {
        // FB event
        if (window.fbq) fbq("track", "InitiateCheckout", {
          value: this.products_price(),
          currency: this.translations.currency_code,
        })
      }
      this.local_loader = false
    }
    this.toggle_preview_cart([false, false, true])
  },
  beforeDestroy() {
    this.deselect_group()
    this.clear_order_links()
    if (this.order_links.length) this.update_temp_order_data(null)
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      toggle_preview_cart: TOGGLE_PREVIEW_BASKET,
      update_groups: UPDATE_BASKET_GROUPS,
      update_selected_group: UPDATE_SELECTED_BASKET_GROUP,
      clear_order_links: CLEAR_ORDER_LINKS
    }),
    ...mapMutations(CLIENT_STORE, {
      update_temp_order_data: UPDATE_TEMP_ORDER_DATA
    }),
    ...mapActions(SHARED_STORE, {
      "set_whole_basket": SET_WHOLE_BASKET
    }),
    insert_variables_into_string,
    update_language(language, id) {
      Vue.set(this.selected_languages, id, language)
    },
    deselect_group() {
      this.selected_group_id = undefined
      this.update_selected_group(undefined)
    },
    select_group(group, id) {
      this.update_selected_group(group)
      this.selected_group_id = id
    },
    update_product_groups() {
      let groups = {}

      if (
        this.is_marketplace_environment ||
        this.has_basket_cross_sold_product ||
        this.order_links?.length
      ) {
        groups = this.basket_items.reduce((total, product) => ({
          ...total,
          [product.project_id || current_store_key]: [
            ...(total[product.project_id || current_store_key] || []),
            product
          ]
        }), {})

        this.selected_languages = Object.keys(groups).reduce((tot, group_id) => ({
          ...tot,
          [group_id]:
            this.selected_languages[group_id] ||
            this.selected_language ||
            ""
        }), {})
      }

      this.update_groups(groups)

      if (this.selected_group_id) this.update_selected_group(groups[this.selected_group_id])
    },
    clear_group(group_id) {
      this.deselect_group()
      const groups = un_bind({
        ...this.get_correct_product_groups,
        [group_id]: undefined
      })
      this.set_whole_basket(Object.values(groups).flatMap(value => value))
    },
    clear_basket() {
      this.set_whole_basket([])
    },
    get_correct_product_prices(products) {
      const available_languages = []

      products.forEach(({ prices }) => {
        if (!prices) return

        Object.keys(prices).forEach(
          language => products.every(({ prices = {} }) => prices[language]) ? available_languages.push(language) : null
        )
      })
      return available_languages.length ?
        Array.from(new Set(available_languages)).reduce((tot, language) => ({ ...tot, [language]: "" }), {}) :
        undefined
    }
  }
}
</script>

<style lang="scss">
@use "../../../styles/_global-constants" as *;
@use "../../../styles/_marketplace-constants" as *;
@use "../../../styles/marketplace" as *;

.basket {
  position: relative;
  min-height: 300px;
  margin-bottom: $spacing--medium;
  color: var(--font_heavy);

  @media (max-width: $basket-breakpoint) {
    max-width: 600px;
    margin: auto;
  }

  &--marketplace {
    @media (max-width: $maximum-width) {
      padding: 0 $spacing--small;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-areas:
      "title title title"
      "items items info"
      "orders orders info"
      "clear-basket clear-basket info";
    align-items: flex-start;
    column-gap: $spacing--small;
    row-gap: $spacing--small;
    grid-template-columns: 1fr 1fr 1fr;

    &--no-items {
      grid-template-areas:
        "title title title"
        "orders orders orders"
    }

    @media (max-width: $basket-breakpoint) {
      grid-template-areas:
        "title title title"
        "items items items"
        "info info info"
        "orders orders orders"
        "clear-basket clear-basket clear-basket";
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(var(--body_color_RGB), 0.6);

    .spinner {
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 20px);
      z-index: 1000;

      &-inner {
        &.loading {
          transform: scale(1.2) !important;
        }
      }
    }
  }

  &__title {
    grid-area: title;
    font-size: $font-size--very-large;
    font-weight: 500;
  }

  &__empty-with-orders {
    font-size: $font-size--very-large;
  }

  &__items {
    position: relative;
    grid-area: items;

    &-remove-group {
      display: inline-block;
    }

    &-group {
      padding: $spacing--small;
      margin-bottom: $spacing--small;
      border-radius: $border-radius;
      border: 1px solid var(--background_heavy);
      text-align: center;
      transition: $default-transition;

      @media (max-width: $tablet) {
        padding: $spacing--tiny;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        margin: 0 0 10px;
        font-size: 12px;
        text-align: left;
      }

      &-bottom {
        display: grid;
        grid-template-columns: 150px 150px;
        column-gap: calc(100% - 300px);
        align-items: center;

        div {
          cursor: pointer;

          &:first-child {
            font-size: $font-size--small;
            text-decoration: underline;
            text-align: left;
          }
        }

        .marketplace-button {
          justify-content: center;
        }
      }

      &--marketplace {
        border: 1px solid $grey--light;
      }

      &--unselected {
        opacity: 0.5;
        filter: blur(2px);
        transform: scale(0.9);
      }

      &--selected {
        @media (max-width: $basket-breakpoint) {
          position: sticky;
          top: calc(#{$menu-height} + #{$spacing--small}); // for header shadow
          left: 0;
          margin: 0;
          background-color: var(--body_color);
          z-index: 2;
        }
      }
    }
  }

  .basket-info {
    position: sticky;
    top: calc(#{$menu-height} + 5px); // for header shadow
    left: 0;
    grid-area: info;

    @media (max-width: $basket-breakpoint) {
      position: relative;
      top: 0;
      left: auto;
    }
  }

  @media (max-width: $tablet) {
    &__items {
      width: 100%;
      padding: 0;
      display: block;
    }

    &__info {
      border-left: none;
      width: 100%;
      padding: 0;
      display: block;
    }
  }

  &__seller {
    margin-bottom: $spacing--small;
    font-size: $font-size--medium;
    text-align: left;

    @media (max-width: $tablet) {
      font-size: $font-size--small;
    }

    @media (max-width: $tablet--small) {
      line-height: $medium-small-icon;
    }

    div {
      float: right;
      font-size: $font-size--small;

      @media (max-width: $tablet) {
        margin-top: 2px;
        font-size: $font-size--tiny;
      }

      &:hover {
        text-decoration: none;
      }
    }

    a {
      font-weight: 500;
      text-decoration: underline !important;
    }
  }

  &__order-link {
    position: relative;
    margin: 10px 0 0 20px;
    padding: 10px 20px;
    border-radius: 6px;
    border: 1px solid var(--font_heavy);

    @media (max-width: $basket-breakpoint) {
      padding-left: $spacing--tiny;
      margin-bottom: $spacing--tiny;
    }

    .thank-you {
      max-width: 100%;
      margin: 0;
      padding: 0 0 10px 0;
      border: none;
      border-top: 1px solid $light-grey;
      border-radius: 0;
      margin-top: 10px;

      &__on-pickup {
        text-align: left;
        padding-left: 20px;
        margin-bottom: 0;
      }

      &__bank-transfer {
        margin: 0;
      }
    }

    &:before {
      position: absolute;
      left: -20px;
      top: 16px;
      height: 8px;
      line-height: 8px;
      content: "•";
      color: var(--background_heavy);
    }

    &s {
      font-size: $font-size--medium;
      text-align: left;
      line-height: 20px;
      grid-area: orders;

      @media (max-width: $basket-breakpoint) {
        font-size: $font-size--small;
        line-height: 17px;
      }

      &-title {
        margin: 0 0 $spacing--medium;
        font-size: $font-size--large;
        font-weight: 500;

        @media (max-width: $basket-breakpoint) {
          margin: 0 0 $spacing--tiny;
          font-size: $font-size--medium;
        }
      }

      a {
        display: inline-block;
        vertical-align: text-top;
        color: var(--background_heavy) !important;
        margin-left: $spacing--tiny;
        font-weight: bold;
      }
    }
  }

  &__clear-basket {
    grid-area: clear-basket;
    max-width: 250px;
    margin: $spacing--small auto 0;
    text-align: center;
    font-size: 13px;
    opacity: 0.5;
    transition: $default-transition;
    cursor: pointer;
    text-decoration: underline;

    svg {
      display: inline-block;
      width: 13px;
      height: 13px;
      vertical-align: middle;
      fill: var(--font_heavy);
    }

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes permanent-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
