<template>
  <section class="basket-item">
    <div
      v-if="product_data.has_product_adjusted_amount"
      class="basket-item__message"
    >
      {{
        is_marketplace_environment ?
          $translate("basket.quanity_changed_on_server", { number: product_data.quantity }) :
          translations.price_has_been_adjusted_by_server | insert_variables_into_string([{ value: product_data.quantity, type: "number" }])
      }}
      <img src="/static/icons/arrow-white.svg" alt="down-arrow" class="basket-item__message__arrow" />
    </div>
    <div class="basket-item__wrapper" :class="{ 'basket-item__wrapper--small': small_size }">
      <div class="basket-item__img">
        <v-lazy-image
          :src="product_data.image.mobile || product_data.image || default_image_src"
          :alt="product_data.title"
        />
      </div>

      <div class="basket-item__info">
        <p v-if="disable_click" class="basket-item__title">
          {{ product_data.title }}
        </p>
        <router-link
          v-else
          :to="create_correct_product_url(product_data, is_marketplace_environment)"
          class="link"
          :aria-label="`Checkout item ${product_data.title}`"
          itemprop="category/subcateogry/item"
        >
          <p class="basket-item__title">
            {{ product_data.title }}
          </p>
        </router-link>
        <div class="basket-item__extra-category__wrapper" v-if="get_product_options.length">
          {{ translations.you_chose }}
          <div
            v-for="({ name, value }, ind) in get_product_options"
            :key="`${product_data.title}-${name}-${value}-${ind}`"
            class="basket-item__extra-category-container"
          >
            {{ name }}:
            <span
              class="basket-item__extra-category"
              :style="{
                backgroundColor: is_value_a_color(value) ? value : 'transparent'
              }"
              :class="{
                'basket-item__extra-category--not-color': !is_value_a_color(value),
              }"
            >
              {{ is_value_a_color(value) ? "" : value }}
            </span>
          </div>
        </div>
      </div>

      <div class="basket-item__checkout">
        <div class="basket-item__adjuster amount-adjuster">
          <input 
            class="amount-adjuster__input"
            label="Number of items in cart"
            type="number"
            v-model="chosen_quantity"
            @change="selected_quantity = chosen_quantity"
          ><div class="amount-adjuster__adjuster">
            <div @click="!disable_click && adjust_amount('+')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
              </svg>
            </div>
            <div @click="!disable_click && adjust_amount('-')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="basket-item__price">
          <b>
            <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
            {{ total_price }}
            <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          </b>
          <p>
            <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
            {{ product_price }}
            <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
             x {{ chosen_quantity }}</p>
        </div
        ><div class="basket-item__remove" @click="!disable_click && delete_item()"><close_icon/></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import create_correct_product_url from "../../../../Client/methods/create_correct_product_url"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import { default_option_name } from "../../../../Admin/constants/others_constants"
import adjust_amount from "../../../../Client/methods/adjust_amount"
import { MARKETPLACE } from "../../../../Marketplace/constants/other"
import round_to_two_decimals from "../../../../Client/methods/round_to_two_decimals"
import close_icon from "../../icon_components/close"
import is_currency_symbol from "../../../methods/is_currency_symbol"
import { default_language } from "../../../../../../functions/constans/other"
import { CHANGE_ADJUSTED_STATE_OF_PRODUCT, CHANGE_QUANTITY_OF_PRODUCT, DELETE_BASKET_ITEM } from "../../../stores/Shared/constants"
import { UPDATE_VIEWED_PRODUCT } from "../../../../Client/stores/Client/constants"
import is_value_a_color from "../../../methods/is_value_a_color"
import { SHARED_STORE, currency_signs, default_image_src } from "../../../constants/other"

export default {
  components: {
    close_icon,
    VLazyImage
  },
  props: {
    product_data: {
      type: Object,
      required: true
    },
    small_size: {
      type: Boolean,
      required: false,
      default: false
    },
    disable_click: {
      type: Boolean,
      required: false,
      default: false
    },
    emit_group_clear: {
      type: Boolean,
      required: false,
      default: false
    },
    selected_language: {
      type: String,
      required: false,
      default: default_language
    },
  },
  data() {
    return {
      chosen_quantity: this.product_data.quantity,
      selected_quantity: this.product_data.quantity,
      quantity_adjusted_from_server: false,
      default_image_src
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "user_is_using_fast_connection",
      "translations",
      "project_styles",
      "use_shopify_data"
    ]),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState([
      "is_marketplace_environment",
    ]),
    product_price_data() {
      return ((this.product_data.prices || {})[this.selected_language]) || this.product_data
    },
    currency_symbol() {
      return this.is_marketplace_environment ?
        currency_signs[
          this.product_price_data.currency_code ||
          this.marketplace_currency
        ] :
        (this.translations.currency_symbol || this.translations.currency_code)
    },
    get_product_options() {
      /**
       * Checks if first option is Shopify default option for products
       * with only one option
       */
      return this.product_data.variant_options[0].value === default_option_name ? [] : this.product_data.variant_options
    },
    total_price() {
      const tax_calculation = 1 + (Number(this.translations.tax_percantage || 0) / 100)

      return round_to_two_decimals((this.product_price_data.price * tax_calculation) * this.chosen_quantity)
    },
    product_price() {
      const tax_calculation = 1 + (Number(this.translations.tax_percantage || 0) / 100)

      return round_to_two_decimals((this.product_price_data.price || this.product_data.price) * tax_calculation)
    }
  },
  watch: {
    "product_data.has_product_adjusted_amount"(val){
      if(val) setTimeout(() => this.change_adjusted_state_of_product(this.product_data), 4000)
    },
    "product_data.quantity"(val){
      this.chosen_quantity = val
      this.selected_quantity = val
    },
    selected_quantity(){
      if (this.product_data.quantity !== this.chosen_quantity) {
        let temp_data = JSON.parse(JSON.stringify(this.product_data))
        temp_data.quantity = Number(this.chosen_quantity)
        this.$emit("items_adjusted_from_basket", true)
        this.change_quantity_of_product(temp_data)
      }
    }
  },
  methods: {
    ...mapMutations(CLIENT_STORE, {
      update_viewed_product_data: UPDATE_VIEWED_PRODUCT,
    }),
    ...mapMutations(SHARED_STORE, {
      delete_basket_item: DELETE_BASKET_ITEM,
      change_quantity_of_product: CHANGE_QUANTITY_OF_PRODUCT,
      change_adjusted_state_of_product: CHANGE_ADJUSTED_STATE_OF_PRODUCT
    }),
    adjust_amount,
    is_value_a_color,
    create_correct_product_url,
    is_currency_symbol,
    delete_item() {
      if (this.emit_group_clear) this.$emit("clear_selected_group")
      this.delete_basket_item(this.product_data)
    }
  }
}
</script>

<style lang="scss">
@use "../../../../../styles/amount_adjuster.scss";
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/_marketplace-constants" as *;

$basket-class: basket-item;

.#{$basket-class} {
  $image-height: 80px;
  margin-bottom: $spacing--small;
  text-align: left;
  padding: $spacing--tiny;

  @media (max-width: $tablet--small) {
    margin-bottom: $spacing--medium;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__wrapper {
    position: relative;
    display: grid;
    grid-template-areas: "image info checkout";
    grid-template-columns: $image-height 1fr 180px;
    color: var(--font_heavy);

    &--small {
      grid-template-columns: $image-height 120px 180px;
    }

    @media (max-width: $tablet--small) {
      padding: 0;
      grid-template-areas:
        "image info"
        "image checkout";
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing--tiny;
    }
  }

  &__extra-category {
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    transition: 250ms ease-out;
    text-transform: capitalize;
    box-shadow: 0 0px 10px 0px $shadow-color;

    &-container {
      line-height: 17px;
    }

    &.#{$basket-class}__extra-category--side-basket {
      margin: 0;
      padding: 0;
    }

    &__wrapper {
      font-size: 13px;
      color: $grey;
      text-transform: capitalize;
    }

    &--not-color {
      width: auto;
      height: auto;
      text-transform: capitalize;
      border: none;
      box-shadow: none;
    }
  }

  &__message {
    position: relative;
    max-height: 0;
    overflow: hidden;
    background-color: $alert-color;
    color: $pure-white;
    text-align: left;
    animation: slide-down 4000ms ease-out;

    &__arrow {
      position: absolute;
      top: 7.5px;
      height: 15px;
      width: 15px;
      right: 10px;
      transform: rotate(90deg);
    }
  }

  &__img {
    display: flex;
    grid-area: image;

    img {
      display: block;
      max-width: $image-height;
      max-height: $image-height;
      margin: auto;
      object-fit: contain;
      object-position: center;
    }

    @media (max-width: $tablet--small) {
      img {
        margin: 0;
        max-width: 120px;
        max-height: 120px;
        width: 100%;
      }
    }
  }

  &__title {
    margin: 0 0 $spacing--tiny;
    font-weight: bold;
    word-break: break-all;

    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }

  &__adjuster {
    display: inline-block;
    vertical-align: middle;
  }

  &__price {
    display: inline-block;
    vertical-align: middle;
    margin-left: $spacing--small;
    width: calc(100% - 50px - #{$spacing--small} - #{$tiny-icon});
    text-align: left;
    font-size: 15px;

    @media (max-width: $mobile) {
      font-size: $font-size--small;
      margin-left: $spacing--tiny;
      width: calc(100% - 30px - #{$spacing--tiny} - #{$tiny-icon});
    }

    p {
      margin: 0 0 5px;
      font-size: 13px;

      @media (max-width: $mobile) {
        font-size: 11px;
      }
    }
  }

  &__info {
    grid-area: info;
    padding: 0 $spacing--small;

    @media (max-width: $tablet--small) {
      padding: 0;
      text-align: right;
    }
  }

  &__checkout {
    display: flex;
    align-items: center;
    position: relative;
    grid-area: checkout;
    padding: 0;

    p  {
      width: 100%;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__remove {
    display: inline-block;
    vertical-align: middle;
    width: $tiny-icon;
    height: $tiny-icon;
    cursor: pointer;

    svg {
      width: $tiny-icon;
      height: $tiny-icon;
    }
  }

  &__preview-basket {
    display: inline-block;
    width: calc(100% - 105px);
    padding-left: 10px;
    box-sizing: border-box;
  }

  .measurments {
    display: none;
  }
}

@keyframes slide-down {
  0% {
    max-height: 0;
    padding: 5px 15px;
    opacity: 0;
  }
  20% {
    max-height: 50px;
    opacity: 1;
  }
  80% {
    max-height: 50px;
    padding: 5px 15px;
    opacity: 1;
  }
  100% {
    display: none;
    max-height: 0;
    padding: 0 15px;
    opacity: 0;
  }
}
</style>
