export default function(type) {
  switch (type) {
    case "+":
      this.selected_quantity = Number(this.chosen_quantity) + 1
      break;
    case "-":
      this.selected_quantity = Number(this.chosen_quantity) <= 1 ? 
        1 :
        Number(this.chosen_quantity) - 1
      break;
  
    default:
      break;
  }
  this.chosen_quantity = this.selected_quantity
}
