<template>
  <div class="basket-info basket-info__wrapper" :class="{ 'basket-info--marketplace': is_marketplace_environment }">
    <p class="basket-info__title">{{ translations.order_summary || $translate("basket.basket_summary") }}</p>
    <div v-if="is_marketplace_environment && basket_items.length && !user" class="basket-info__login">
      <p>{{ translations.log_in_message || $translate("basket.log_in_message") }}</p>
      <div
        @click="$router.push('/login')"
        class="marketplace-button marketplace-button--basic"
      >{{ translations.log_in_message || $translate("basket.log_in") }}</div>
    </div>

    <hr>

    <div
      v-if="show_checkout_content"
      class="basket-info__submit"
    >
      <div class="basket-info__form">
        <shipping_info
          v-if="basket_requires_shipping(selected_group)"
          :selected_group_currency="get_correct_currency"
          :selected_group_language="selected_group_language || selected_language"
        />

        <div class="basket-info__prices">
          <p>{{ translations.subtotal || $translate("basket.subtotal") }}</p>
          <p data-cy="subtotal" class="align-right">
            <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
            {{ products_price(selected_group, selected_group_language || selected_language) }}
            <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          </p>
          <div data-cy="tax" v-if="Number(translations.tax_percantage || group_tax)">
            <p>{{ translations.tax_title || $translate("basket.tax") }}</p
            ><p class="align-right">{{ is_selected_group_cross_or_marketplace ? group_tax : translations.tax_percantage }}%</p>
          </div>
          <div v-if="basket_requires_shipping(selected_group) && shipping_option && shipping_option.price !== undefined">
            <p>{{ translations.shipping_info ? translations.shipping_info.shipping_price_title : $translate("basket.shipping_price") }}</p
            ><p class="align-right">
              <span v-if="is_currency_symbol(currency_symbol) && shipping_option.price">{{ currency_symbol }}</span>
              {{ shipping_option.price || translations.free || $translate("basket.free") }}
              <span v-if="!is_currency_symbol(currency_symbol) && shipping_option.price">{{ currency_symbol }}</span>
            </p>
          </div>
          <div data-cy="total" class="basket-info__total">
            <p>{{ translations.total || $translate("basket.total_price") }}</p
            ><p class="align-right">
              <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
              {{ total_price(selected_group, group_tax, selected_group_language || selected_language) }}
              <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
            </p>
          </div>
        </div>

        <div class="basket-info__discount">
          <div v-if="!discount_code" class="basket-info__discount-error">
            {{ translations.discount_code_error || $translate('basket.discount_code_error')  }}
          </div>
          <div v-if="discount_code && discount_code.type" class="basket-info__discount-preview">
            <div><b>{{ translations.discount_code || $translate('basket.discount_code') }}:</b> {{ discount_code.code }}</div>
            <div @click="clear_discount"><trash_can /></div>
          </div>
          <div v-else class="basket-info__discount-input">
            <input
              data-cy="discount-code-checkout"
              v-model="discount_code_name"
              type="text"
              :placeholder="translations.discount_code || $translate('basket.discount_code')"
              :aria-placeholder="translations.discount_code || $translate('basket.discount_code')"
            >
            <button
              data-cy="apply-discount"
              class="button"
              @click="verify_discount([discount_code_name, selected_group_language, selected_group])"
            >{{ translations.apply || $translate("apply") }}</button>
          </div>
        </div>

        <div class="basket-info__company-inputs">
          <div>
            {{ translations.company_purchase_checkbox || $translate("basket.company_purchase_checkbox")  }}
            <div class="store-checkbox">
              <input
                v-model="company_purchase" type="checkbox" name="company_purchase" id="company_purchase"
              />
              <label for="company_purchase" />
            </div>
          </div>
          <div v-if="company_purchase">
            <input
              v-model="company"
              type="text"
              :placeholder="translations.company || $translate('basket.company')"
              :aria-placeholder="translations.company || $translate('basket.company')"
              class="store-input"
            >
            <input
              v-model="vatID"
              type="text"
              :placeholder="translations.VAT_id || $translate('basket.VAT_id')"
              :aria-placeholder="translations.VAT_id || $translate('basket.VAT_id')"
              class="store-input"
            >
            <input
              v-model="businessID"
              type="text"
              :placeholder="translations.business_id || $translate('basket.business_id')"
              :aria-placeholder="translations.business_id || $translate('basket.business_id')"
              class="store-input"
            >
          </div>
        </div>

        <payment_component
          v-if="!is_marketplace_environment || ((is_marketplace_environment || is_product_cross_selling) && selected_group)"
          :selected_group_currency="selected_group_currency"
          :selected_group_language="selected_group_language"
          :company_info="{
            company,
            vatID,
            businessID
          }"
          :is_product_cross_selling="is_product_cross_selling"
        />
      </div>
    </div>
    <p v-if="show_group_selection_message">
      {{ translations.select_group || $translate("basket.select_group") }}
    </p>
    <p v-if="(is_marketplace_environment || is_product_cross_selling) && !selected_group && !basket_items.length">
      {{ translations.empty_basket || $translate("basket.empty_basket") }}
    </p>
    <payment_component v-if="!is_demo_account && use_shopify_data"/>
    <p v-else-if="is_demo_account">This is a Demo website, is not possible to buy anything from us.</p>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import { MARKETPLACE } from "../../../../Marketplace/constants/other"
import { currency_signs, current_store_key, SHARED_STORE } from "../../../constants/other"
import is_currency_symbol from "../../../methods/is_currency_symbol"
import trash_can from "../../icon_components/trash-can"
import {
  DOES_BASKET_REQUIRE_SHIPPING, GET_PRODUCT_PRICE, GET_TOTAL_PRICE,
  VALIDATE_BASKET, VERIFY_AND_SET_DISCOUNT, UPDATE_DISCOUNT_CODE
} from "../../../stores/Shared/constants"
import { basket_update_timestamp, half_hour_in_ms } from '../../../../../data/other_constants'

const shipping_info = () => import("./shipping_info")
const payment_component = () => import("./payment_component")

export default {
  props: {
    selected_group_currency: {
      type: String,
      required: false,
      default: ""
    },
    selected_group_language: {
      type: String,
      required: false,
      default: ""
    },
    is_product_cross_selling: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    payment_component,
    shipping_info,
    trash_can
  },
  data() {
    return {
      discount_code_name: "",
      company: "",
      vatID: "",
      businessID: "",
      company_purchase: false
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "use_shopify_data",
      "is_demo_account",
      "shipping_option",
      "selected_language",
      "discount_code"
    ]),
    ...mapState(SHARED_STORE, [
      "discount_code",
      "basket_items",
      "selected_group",
      "order_links",
      "basket_product_groups"
    ]),
    ...mapGetters(SHARED_STORE, {
      products_price: GET_PRODUCT_PRICE,
      total_price: GET_TOTAL_PRICE,
      basket_requires_shipping: DOES_BASKET_REQUIRE_SHIPPING
    }),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState([
      "is_marketplace_environment",
      "user"
    ]),
    show_checkout_content() {
      if (this.selected_group) return true
      if (
        !this.is_marketplace_environment &&
        !this.use_shopify_data &&
        !this.is_demo_account &&
        !this.is_product_cross_selling &&
        !this.order_links.length &&
        !this.selected_group &&
        !Object.keys(this.basket_product_groups).length
      ) return true
      return false
    },
    show_group_selection_message() {
      if (
        (this.is_marketplace_environment || this.is_product_cross_selling) &&
        this.basket_items.length &&
        !this.selected_group
      ) return true
      if (
        this.order_links &&
        Object.keys(this.basket_product_groups).length &&
        !this.selected_group
      ) return true
      return false
    },
    is_selected_group_cross_or_marketplace() {
      return this.selected_group?.[0]?.project_id
    },
    group_tax() {
      // Is group for cross or marketplace products
      return (this.selected_group && this.is_selected_group_cross_or_marketplace) ?
        this.selected_group?.[0]?.taxes[this.selected_group_language] :
        ""
    },
    get_correct_currency() {
      return this.is_marketplace_environment ?
        (this.selected_group_currency || this.marketplace_currency) :
        this.translations.currency_code
    },
    currency_symbol() {
      return this.is_marketplace_environment ?
        currency_signs[this.get_correct_currency] :
        this.translations.currency_symbol || this.get_correct_currency
    },
  },
  watch: {
    selected_group: {
      handler(group) {
        this.clear_discount()
        this.update_shipping_option({})
        this.update_free_shipping_state(false)

        if (group) this.re_validate_basket(group)
      },
      deep: true
    },
    basket_items() {
      this.clear_discount()
    }
  },
  mounted() {
    // Has to be assigned like this to avoid event being passed
    window.onfocus = () => this.re_validate_basket()
  },
  methods: {
    ...mapMutations(CLIENT_STORE, [
      "update_shipping_option",
      "update_free_shipping_state",
    ]),
    ...mapActions(SHARED_STORE, {
      verify_discount: VERIFY_AND_SET_DISCOUNT,
      validate_basket: VALIDATE_BASKET
    }),
    ...mapMutations(SHARED_STORE, {
      set_discount_code: UPDATE_DISCOUNT_CODE
    }),
    clear_discount() {
      this.discount_code_name = ""
      this.set_discount_code()
    },
    async re_validate_basket(selected_group) {
      const last_basket_update = Number(window.localStorage.getItem(basket_update_timestamp))

      // Check if basket older than 30 mins and update
      if ((new Date().getTime() - last_basket_update) > half_hour_in_ms) {
        await this.validate_basket()

        if (selected_group && !this.selected_group) this.$nextTick(
          () => this.$emit("select_group", selected_group, selected_group[0].project_id || current_store_key)
        )
      }
    },
    is_currency_symbol
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_marketplace-constants" as *;
  @use "../../../../../styles/marketplace" as *;
  @use "../../../../../styles/store_checkbox";
  @use "../../../../../styles/store_input";

.basket-info {
  width: 100%;
  padding: $spacing--small;
  border-radius: $border-radius;
  border: 1px solid var(--background_heavy);

  &--marketplace {
    border: 1px solid $grey--light;
  }

  &__title {
    margin: 0 0 $spacing--small;
    font-size: $font-size--large;
    font-weight: 500;
  }

  &__prices {
    p {
      display: inline-block;
      width: calc(50% - 3px);
      margin: 0;
      text-align: left;

      &.align-right{
        text-align: right;
      }
    }
  }

  &__company-inputs {
    margin-bottom: $spacing--medium;
  }

  &__discount {
    margin-bottom: $spacing--small;

    &-preview {
      display: grid;
      grid-template-columns: calc(100% - #{$spacing--medium} - #{$spacing--small}) $spacing--medium;
      column-gap: $spacing--small;
      text-align: left;
      align-items: center;

      div {
        &:first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      svg {
        display: block;
        width: $tiny-icon;
        height: $tiny-icon;
        margin: 0 auto;
        cursor: pointer;
        fill: var(--background_heavy);
      }
    }

    &-error {
      padding: $spacing--tiny;
      margin-bottom: $spacing--tiny;
      background-color: $alert-color;
      color: $pure-white;
      font-size: $font-size--small;
      line-height: normal;
      text-align: left;
      border-radius: $border-radius;
    }

    &-input {
      display: grid;
      grid-template-columns: calc(100% - 100px - #{$spacing--small}) 100px;
      column-gap: $spacing--small;

      input {
        line-height: 36px;
        border: 1px solid var(--background_heavy);
        border-radius: 3px;
        font-size: $font-size--medium;
        text-align: center;

        &::placeholder {
          text-align: center !important;
        }
      }

      button {
        padding: 0 10px;
        margin: 0;
        border-radius: 3px;
        font-size: $font-size--medium;
      }
    }
  }

  &__total {
    font-weight: 500;
    margin: 0 0 20px;
  }

  &__submit {
    line-height: 35px;
    text-align: center;
  }

  &__login {
    p {
      display: inline-block;
      width: 70%;
      vertical-align: middle;
      margin: 0;
    }

    .marketplace-button {
      float: right;
    }
  }
}

</style>
